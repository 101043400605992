import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './NavBar.module.css';

function NavBar() {
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  return (
    <nav className={styles.nav}>
      <ul className={styles.navList}>
        <li><Link to="/" className={styles.navLink}>Home</Link></li>
        <li><Link to="/quizzes" className={styles.navLink}>Quizzes</Link></li>
        {!user ? (
          <>
            <li><Link to="/register" className={styles.navLink}>Register</Link></li>
            <li><Link to="/login" className={styles.navLink}>Login</Link></li>
          </>
        ) : (
          <>
            <li><Link to="/profile" className={styles.navLink}>Profile</Link></li>
            <li><button onClick={logout} className={styles.logoutButton}>Logout</button></li>
          	{user.role === 'architect' && (
              <li><Link to="/architect" className={styles.navLink}>Architect Dashboard</Link></li>
            )}
          </>
        )}
      </ul>
    </nav>
  );
}

export default NavBar;
