import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../utils/axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../FormElements.module.css';
import ArchitectNav from './ArchitectNav';

function QuizForm() {
  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    description: '',
    details: '',
    featuredImage: '',
    category: '',
    allottedTime: '',
  });
  const [questions, setQuestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

	const [imageFile, setImageFile] = useState(null);

  const fetchCategories = useCallback(async () => {
    try {
      const res = await axios.get('/api/categories');
      setCategories(res.data);
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  }, []);

	const renderCategoryOptions = (categoryList, level = 0) => {
    return categoryList.map(cat => (
      <React.Fragment key={cat._id}>
        <option value={cat._id} style={{ marginLeft: `${level * 20}px` }}>
          {'—'.repeat(level)} {cat.name}
        </option>
        {cat.subcategories && renderCategoryOptions(cat.subcategories, level + 1)}
      </React.Fragment>
    ));
  };

  const fetchQuiz = useCallback(async () => {
    if (!id) return;
    try {
      const res = await axios.get(`/api/quizzes/id/${id}`);
      setFormData({
        title: res.data.title,
        slug: res.data.slug,
        description: res.data.description,
        details: res.data.details,
        featuredImage: res.data.featuredImage,
        category: res.data.category,
        allottedTime: res.data.allottedTime,
      });
      const questionsWithType = res.data.questions.map(q => ({
      	...q,
      	questionType: q.questionType || 'MCQ' // Default to MCQ if not set
    	}));
      setQuestions(questionsWithType);
    } catch (err) {
      console.error('Error fetching quiz:', err);
    }
  }, [id]);

  useEffect(() => {
    fetchCategories();
    fetchQuiz();
  }, [fetchCategories, fetchQuiz]);

  const { title, slug, description, details, featuredImage, category, allottedTime } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const addQuestion = (type) => {
    const newQuestion = {
      questionType: type,
      questionText: '',
      answers: type === 'MCQ' ? [{ answerText: '', isCorrect: false }] :
               type === 'TrueFalse' ? [{ answerText: 'True', isCorrect: false }, { answerText: 'False', isCorrect: false }] :
               [],
      correctAnswers: type === 'MultipleTextAnswer' ? [''] : undefined,
      caseSensitive: false
    };
    setQuestions([...questions, newQuestion]);
  };

  const updateQuestion = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const addAnswer = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].answers.push({ answerText: '', isCorrect: false });
    setQuestions(updatedQuestions);
  };

  const updateAnswer = (questionIndex, answerIndex, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].answers[answerIndex][field] = value;
    setQuestions(updatedQuestions);
  };

	const addCorrectAnswer = (questionIndex) => {
    const updatedQuestions = [...questions];
    if (!updatedQuestions[questionIndex].correctAnswers) {
      updatedQuestions[questionIndex].correctAnswers = [];
    }
    updatedQuestions[questionIndex].correctAnswers.push('');
    setQuestions(updatedQuestions);
  };

  const updateCorrectAnswer = (questionIndex, answerIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].correctAnswers[answerIndex] = value;
    setQuestions(updatedQuestions);
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const uploadImage = async () => {
    if (!imageFile) return;

    const formData = new FormData();
    formData.append('image', imageFile);

    try {
      const res = await axios.post('/api/uploads', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return res.data.imageUrl;
    } catch (err) {
      console.error('Error uploading image:', err);
      return null;
    }
  };

  const onSubmit = async e => {
    e.preventDefault();

    let updatedFormData = { ...formData };

    if (imageFile) {
      const newImageUrl = await uploadImage();
      if (newImageUrl) {
        updatedFormData.featuredImage = newImageUrl;
      }
    }

    try {
      const quizData = {
        ...updatedFormData,
        questions
      };
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token')
        }
      };
      if (id) {
        await axios.put(`/api/quizzes/${id}`, quizData, config);
      } else {
        await axios.post('/api/quizzes', quizData, config);
      }
      navigate('/architect/quizzes');
    } catch (err) {
      console.error('Error saving quiz:', err.response ? err.response.data : err.message);
    }
  };

  return (
  <div>
    <ArchitectNav />
    <form onSubmit={onSubmit} className={styles.formContainer}>
      <h2>{id ? 'Edit Quiz' : 'Create New Quiz'}</h2>
      <div className={styles.formGroup}>
        <label htmlFor="title" className={styles.formLabel}>Title</label>
        <input
          type="text"
          id="title"
          name="title"
          value={title}
          onChange={onChange}
          required
          className={styles.formInput}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="slug" className={styles.formLabel}>Slug</label>
        <input
          type="text"
          id="slug"
          name="slug"
          value={slug}
          onChange={onChange}
          required
          className={styles.formInput}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="description" className={styles.formLabel}>Description</label>
        <textarea
          id="description"
          name="description"
          value={description}
          onChange={onChange}
          required
          className={styles.formInput}
          rows="3"
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="details" className={styles.formLabel}>Details</label>
        <textarea
          id="details"
          name="details"
          value={details}
          onChange={onChange}
          className={styles.formInput}
          rows="5"
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="featuredImage" className={styles.formLabel}>Featured Image</label>
        <input
          type="file"
          id="featuredImage"
          onChange={handleImageChange}
          className={styles.formInput}
        />
        {formData.featuredImage && (
          <img
            src={featuredImage}
            alt="Featured"
            className={styles.previewImage}
          />
        )}
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="category" className={styles.formLabel}>Category</label>
        <select
          id="category"
          name="category"
          value={category}
          onChange={onChange}
          required
          className={styles.formInput}
        >
          <option value="">Select a category</option>
          {renderCategoryOptions(categories)}
        </select>
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="allottedTime" className={styles.formLabel}>Allotted Time (minutes)</label>
        <input
          type="number"
          id="allottedTime"
          name="allottedTime"
          value={allottedTime}
          onChange={onChange}
          required
          className={styles.formInput}
        />
      </div>

      <h3>Questions</h3>
      {questions.map((question, qIndex) => (
        <div key={qIndex} className={styles.formGroup}>
          <select
            value={question.questionType}
            onChange={(e) => updateQuestion(qIndex, 'questionType', e.target.value)}
            className={styles.formInput}
          >
            <option value="MCQ">Multiple Choice</option>
            <option value="TrueFalse">True/False</option>
            <option value="ShortAnswer">Short Answer</option>
            <option value="MultipleTextAnswer">Multiple Text Answer</option>
          </select>
          <input
            type="text"
            value={question.questionText}
            onChange={(e) => updateQuestion(qIndex, 'questionText', e.target.value)}
            placeholder="Question Text"
            className={styles.formInput}
          />
          {question.questionType === 'MCQ' && (
            <>
              {question.answers.map((answer, aIndex) => (
                <div key={aIndex}>
                  <input
                    type="text"
                    value={answer.answerText}
                    onChange={(e) => updateAnswer(qIndex, aIndex, 'answerText', e.target.value)}
                    placeholder="Answer Text"
                    className={styles.formInput}
                  />
                  <input
                    type="checkbox"
                    checked={answer.isCorrect}
                    onChange={(e) => updateAnswer(qIndex, aIndex, 'isCorrect', e.target.checked)}
                  />
                </div>
              ))}
              <button type="button" onClick={() => addAnswer(qIndex)} className={styles.formButton}>Add Answer</button>
            </>
          )}
          {question.questionType === 'TrueFalse' && (
            <>
              <input
                type="radio"
                name={`correct-${qIndex}`}
                onChange={() => {
                  updateAnswer(qIndex, 0, 'isCorrect', true);
                  updateAnswer(qIndex, 1, 'isCorrect', false);
                }}
              /> True
              <input
                type="radio"
                name={`correct-${qIndex}`}
                onChange={() => {
                  updateAnswer(qIndex, 0, 'isCorrect', false);
                  updateAnswer(qIndex, 1, 'isCorrect', true);
                }}
              /> False
            </>
          )}
          {question.questionType === 'ShortAnswer' && (
            <input
              type="text"
              value={question.correctAnswer || ''}
              onChange={(e) => updateQuestion(qIndex, 'correctAnswer', e.target.value)}
              placeholder="Correct Answer"
              className={styles.formInput}
            />
          )}
          {question.questionType === 'MultipleTextAnswer' && (
            <>
              {question.correctAnswers && question.correctAnswers.map((answer, aIndex) => (
                <input
                  key={aIndex}
                  type="text"
                  value={answer}
                  onChange={(e) => updateCorrectAnswer(qIndex, aIndex, e.target.value)}
                  placeholder="Correct Answer"
                  className={styles.formInput}
                />
              ))}
              <button type="button" onClick={() => addCorrectAnswer(qIndex)} className={styles.formButton}>Add Another Correct Answer</button>
              <label>
                <input
                  type="checkbox"
                  checked={question.caseSensitive}
                  onChange={(e) => updateQuestion(qIndex, 'caseSensitive', e.target.checked)}
                />
                Case Sensitive
              </label>
            </>
          )}
        </div>
      ))}
      <button type="button" onClick={() => addQuestion('MCQ')} className={styles.formButton}>Add MCQ</button>
      <button type="button" onClick={() => addQuestion('TrueFalse')} className={styles.formButton}>Add True/False</button>
      <button type="button" onClick={() => addQuestion('ShortAnswer')} className={styles.formButton}>Add Short Answer</button>
      <button type="button" onClick={() => addQuestion('MultipleTextAnswer')} className={styles.formButton}>Add Multiple Text Answer</button>
      <button type="submit" className={styles.formButton}>{id ? 'Update Quiz' : 'Create Quiz'}</button>
    </form>
  </div>
  );
}

export default QuizForm;
