import React from 'react';
import ArchitectNav from './ArchitectNav';

function ArchitectDashboard() {
  return (
    <div>
    	<ArchitectNav />
    </div>
  );
}

export default ArchitectDashboard;
