import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import ArchitectNav from './ArchitectNav';

function UserManagement() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get('/api/architect/users');
        setUsers(res.data);
      } catch (err) {
        console.error('Error fetching users:', err);
      }
    };
    fetchUsers();
  }, []);

  const updateUserRole = async (userId, newRole) => {
    try {
      await axios.put(`/api/architect/users/${userId}/role`, { role: newRole }, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      // Update local state or refetch users
      const updatedUsers = users.map(user =>
        user._id === userId ? { ...user, role: newRole } : user
      );
      setUsers(updatedUsers);
    } catch (err) {
      console.error('Error updating user role:', err);
    }
  };

  return (
    <div>
    	<ArchitectNav />
      <h2>User Management</h2>
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user._id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <select
                  value={user.role}
                  onChange={(e) => updateUserRole(user._id, e.target.value)}
                >
                  <option value="user">User</option>
                  <option value="architect">Architect</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UserManagement;
