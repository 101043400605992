import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import { Link } from 'react-router-dom';
import styles from '../FormElements.module.css';

function QuizList() {
  const [quizzes, setQuizzes] = useState([]);

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const res = await axios.get('/api/quizzes');
        setQuizzes(res.data);
      } catch (err) {
        console.error('Error fetching quizzes:', err);
      }
    };

    fetchQuizzes();
  }, []);

  return (
    <div>
      <h2>Available Quizzes</h2>
      <div className={styles.quizList}>
        {quizzes.map(quiz => (
          <div key={quiz._id} className={styles.quizItem}>
            <Link to={`/quiz/${quiz.slug}`}>
              <img src={quiz.featuredImage} alt={quiz.title} className={styles.quizImage} />
          		<div className="quiz-info">
            		<h3>{quiz.title}</h3>
            		<p>{quiz.description}</p>
          		</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default QuizList;
