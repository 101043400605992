import React from 'react';
import { Helmet } from 'react-helmet';
import QuizList from './quiz/QuizList';


function Home() {
  return (
    <div>
    	<Helmet>
      	<title>El Trivia Fun Quizzes & Games For Learning</title>
        <meta name="description" content="Welcome to El Trivia. Test your knowledge with our exciting quizzes and expand your skills!" />
        <meta property="og:title" content="El Trivia Fun Quizzes & Games For Learning" />
        <meta property="og:description" content="Welcome to El Trivia. Test your knowledge with our exciting quizzes and expand your skills!" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://el.dev.iinix.com" />
    		<link rel="canonical" href="https://el.dev.iinix.com/" />
    		<link rel="shortlink" href="https://el.dev.iinix.com/" />
      </Helmet>
      <h1>El Trivia Fun Quizzes & Games For Learning</h1>
      <p>Test your knowledge with our amazing quizzes!</p>
      <QuizList />
    </div>
  );
}

export default Home;
