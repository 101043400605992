import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../utils/axiosConfig';

function VerifyEmail() {
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');
  const { token } = useParams();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const res = await axios.get(`/api/users/verify-email/${token}`);
        setVerificationStatus(res.data.msg);
      } catch (err) {
        setVerificationStatus('Verification failed. Please try again or contact support.');
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div>
      <h2>Email Verification</h2>
      <p>{verificationStatus}</p>
    </div>
  );
}

export default VerifyEmail;
