import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import NavBar from './NavBar';
import styles from './Layout.module.css';
import logo from '../../assets/el-trivia-logo.png';

function Layout({ children }) {
  return (
    <div className={styles.appContainer}>
    	<Helmet>
<html lang="en-US" prefix="og: http://ogp.me/ns#" />
	<link rel="profile" href="http://gmpg.org/xfn/11" />
	<meta name="robots" content="index,follow,max-image-preview:large" />
	<link rel="icon" href={logo} type="image/x-icon" />
	<link rel="shortcut icon" href={logo} type="image/x-icon" />
	<link rel="icon" href={logo} sizes="32x32" />
	<link rel="icon" href={logo} sizes="192x192" />
	<meta name="msapplication-TileImage" content={logo} />

	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
        {/* You can add more default meta tags here */}
      </Helmet>
      <header className={styles.header}>
      	<div className={styles.headerBox}>
        	<Link to="/" className={styles.logoLink}>
          	<img src={logo} alt="El Trivia Logo" className={styles.logo} />
        	</Link>
        	<NavBar />
      	</div>
      </header>
      <main className={styles.main}>{children}</main>
      <footer className={styles.footer}>
        <p>&copy; 2024 El Trivia. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Layout;
