import React, { useState } from 'react';
import axios from '../../utils/axiosConfig';
import styles from '../FormElements.module.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [error, setError] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('/api/users/forgot-password', { email });
      setMessage(res.data.msg);
    } catch (err) {
      setError(err.response.data.msg || 'An error occurred');
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2>Forgot Password</h2>
      <form onSubmit={onSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="email" className={styles.formLabel}>Email</label>
        	<input
          	type="email"
          	id="email"
          	placeholder="Enter your email"
          	value={email}
          	onChange={(e) => setEmail(e.target.value)}
          	required
            className={styles.formInput}
        	/>
        </div>
        {error && <p className={styles.formError}>{error}</p>}
        <button type="submit" className={styles.authButton}>Send Reset Link</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default ForgotPassword;
