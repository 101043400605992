import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import styles from '../FormElements.module.css';
import ArchitectNav from './ArchitectNav';

function CategoryManagement() {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ name: '', description: '', parentId: '', slug: '' });
  const [editingCategory, setEditingCategory] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const res = await axios.get('/api/categories');
      setCategories(res.data);
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  const handleNewCategoryChange = (e) => {
    const { name, value } = e.target;
    setNewCategory(prev => ({
      ...prev,
      [name]: value,
      slug: name === 'name' ? generateSlug(value) : prev.slug
    }));
  };

  const handleEditCategoryChange = (e) => {
    const { name, value } = e.target;
    setEditingCategory(prev => ({
      ...prev,
      [name]: value,
      slug: name === 'name' ? generateSlug(value) : prev.slug
    }));
  };

  const generateSlug = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
  };

  const createCategory = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/categories', newCategory);
      setNewCategory({ name: '', description: '', parentId: '', slug: '' });
      fetchCategories();
    } catch (err) {
      console.error('Error creating category:', err);
    }
  };

  const updateCategory = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/api/categories/${editingCategory._id}`, editingCategory);
      setEditingCategory(null);
      fetchCategories();
    } catch (err) {
      console.error('Error updating category:', err);
    }
  };

  const deleteCategory = async (id) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        await axios.delete(`/api/categories/${id}`);
        fetchCategories();
      } catch (err) {
        console.error('Error deleting category:', err);
      }
    }
  };

  const renderCategoryTree = (categoryList, level = 0) => {
    return categoryList.map(category => (
      <React.Fragment key={category._id}>
        <li style={{ marginLeft: `${level * 20}px` }}>
          {editingCategory && editingCategory._id === category._id ? (
            <form onSubmit={updateCategory} className={styles.formContainer}>
              <input
                type="text"
                name="name"
                value={editingCategory.name}
                onChange={handleEditCategoryChange}
                required
                className={styles.formInput}
              />
              <input
                type="text"
                name="slug"
                value={editingCategory.slug}
                onChange={handleEditCategoryChange}
                required
                className={styles.formInput}
              />
              <input
                type="text"
                name="description"
                value={editingCategory.description}
                onChange={handleEditCategoryChange}
                className={styles.formInput}
              />
              <select
                name="parentId"
                value={editingCategory.parentId || ''}
                onChange={handleEditCategoryChange}
                className={styles.formInput}
              >
                <option value="">No Parent</option>
                {categories.filter(c => c._id !== category._id).map(c => (
                  <option key={c._id} value={c._id}>{c.name}</option>
                ))}
              </select>
              <button type="submit" className={styles.formButton}>Save</button>
              <button type="button" onClick={() => setEditingCategory(null)} className={styles.formButton}>Cancel</button>
            </form>
          ) : (
            <>
              <strong>{category.name}</strong> (/{category.slug}): {category.description}
              <button onClick={() => setEditingCategory(category)} className={styles.formButton}>Edit</button>
              <button onClick={() => deleteCategory(category._id)} className={styles.formButton}>Delete</button>
            </>
          )}
        </li>
        {category.subcategories && renderCategoryTree(category.subcategories, level + 1)}
      </React.Fragment>
    ));
  };

  return (
    <div>
      <ArchitectNav />
      <h2>Category Management</h2>
      <form onSubmit={createCategory} className={styles.formContainer}>
        <input
          type="text"
          name="name"
          value={newCategory.name}
          onChange={handleNewCategoryChange}
          placeholder="Category Name"
          required
          className={styles.formInput}
        />
        <input
          type="text"
          name="slug"
          value={newCategory.slug}
          onChange={handleNewCategoryChange}
          placeholder="Category Slug"
          required
          className={styles.formInput}
        />
        <input
          type="text"
          name="description"
          value={newCategory.description}
          onChange={handleNewCategoryChange}
          placeholder="Category Description"
          className={styles.formInput}
        />
        <select
          name="parentId"
          value={newCategory.parentId}
          onChange={handleNewCategoryChange}
          className={styles.formInput}
        >
          <option value="">No Parent</option>
          {categories.map(category => (
            <option key={category._id} value={category._id}>{category.name}</option>
          ))}
        </select>
        <button type="submit" className={styles.formButton}>Create Category</button>
      </form>
      <ul>
        {renderCategoryTree(categories)}
      </ul>
    </div>
  );
}

export default CategoryManagement;
