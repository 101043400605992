import React from 'react';
import { Link } from 'react-router-dom';

function ArchitectNav() {
  return (
  	<div>
      <h1>Architect Dashboard</h1>
      <nav>
        <ul>
          <li><Link to="/architect/users">User Management</Link></li>
          <li><Link to="/architect/quizzes">Quiz Management</Link></li>
          <li><Link to="/architect/categories">Category Management</Link></li>
        </ul>
      </nav>
  	</div>
  );
}

export default ArchitectNav;
