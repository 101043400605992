import React, { useState } from 'react';
import axios from '../../utils/axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import styles from '../FormElements.module.css';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return setMessage('Passwords do not match');
    }
    try {
      const res = await axios.post(`/api/users/reset-password/${token}`, { password });
      setMessage(res.data.msg);
      setTimeout(() => navigate('/login'), 3000);
    } catch (err) {
      setMessage(err.response.data.msg);
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2>Reset Password</h2>
      <form onSubmit={onSubmit}>
        <div className={styles.formGroup}>
        <input
          type="password"
          placeholder="Enter new password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
            className={styles.formInput}
        />
    		</div>
        <div className={styles.formGroup}>
        <input
          type="password"
          placeholder="Confirm new password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
            className={styles.formInput}
        />
        </div>
        <button type="submit" className={styles.authButton}>Reset Password</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default ResetPassword;
