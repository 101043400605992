import React, { useState } from 'react';
import axios from '../../utils/axiosConfig';
import styles from '../FormElements.module.css';

function Profile() {
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  const [error, setError] = useState('');

  const { currentPassword, newPassword, confirmNewPassword } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      return alert('New passwords do not match');
    }
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token')
        }
      };
      const body = JSON.stringify({ currentPassword, newPassword });
      const res = await axios.put('/api/users/change-password', body, config);
      alert(res.data.msg);
      setFormData({ currentPassword: '', newPassword: '', confirmNewPassword: '' });
    } catch (err) {
      setError(err.response.data.msg || 'An error occurred');
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2>Change Password</h2>
      <form onSubmit={onSubmit}>
        <div>
          <input
            type="password"
            placeholder="Current Password"
            name="currentPassword"
            value={currentPassword}
            onChange={onChange}
            required
            className={styles.formInput}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="New Password"
            name="newPassword"
            value={newPassword}
            onChange={onChange}
            required
            className={styles.formInput}
          />
        </div>
        <div className={styles.formGroup}>
          <input
            type="password"
            placeholder="Confirm New Password"
            name="confirmNewPassword"
            value={confirmNewPassword}
            onChange={onChange}
            required
            className={styles.formInput}
          />
        </div>
        {error && <p className={styles.formError}>{error}</p>}
        <input type="submit" value="Change Password" />
      </form>
    </div>
  );
}

export default Profile;
