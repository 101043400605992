import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import { Link } from 'react-router-dom';
import styles from '../FormElements.module.css';
import ArchitectNav from './ArchitectNav';

function QuizManagement() {
  const [quizzes, setQuizzes] = useState([]);

  useEffect(() => {
    fetchQuizzes();
  }, []);

  const fetchQuizzes = async () => {
    try {
      const res = await axios.get('/api/quizzes');
      setQuizzes(res.data);
    } catch (err) {
      console.error('Error fetching quizzes:', err);
    }
  };

	const deleteQuiz = async (id) => {
  	if (window.confirm('Are you sure you want to delete this quiz?')) {
    	try {
      	await axios.delete(`/api/quizzes/${id}`);
      	setQuizzes(quizzes.filter(quiz => quiz._id !== id));
    	} catch (err) {
      	if (err.response && err.response.status === 401) {
        	// Handle unauthorized error
        	alert('Your session has expired. Please log in again.');
        	// Redirect to login page will be handled by axios interceptor
      	} else {
        	console.error('Error deleting quiz:', err);
        	alert('Failed to delete quiz. Please try again.');
      	}
    	}
  	}
	};

  return (
    <div>
    	<ArchitectNav />
      <h2>Quiz Management</h2>
      <Link to="/architect/quizzes/create" className={styles.formButton}>Create New Quiz</Link>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Title</th>
            <th>Category</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {quizzes.map(quiz => (
            <tr key={quiz._id}>
              <td>{quiz.title}</td>
              <td>{quiz.category.name}</td>
              <td>
                <Link to={`/architect/quizzes/edit/${quiz._id}`} className={styles.formButton}>Edit</Link>
                <button onClick={() => deleteQuiz(quiz._id)} className={styles.deleteButton}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default QuizManagement;
